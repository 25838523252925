/**
 * Nuxt middleware for preventing users from accessing
 * unauthorized routes.
 * @example
 * ```ts
 * setup() {
 *   definePageMeta({ layout: 'default', middleware: [] });
 * }
 * ```
 * @package fun-academy-campus
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const name: string = to.name?.toString() ?? '';
  if (name && AUTH_ROUTES.includes(name)) return;
  if (await getCurrentUser()) return;
  return await navigateTo(
    useLocalePath()({
      name: 'sign-out',
      query: { skip: 'loading', redirect: to.fullPath },
    })
  );
});
